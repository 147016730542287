import * as React from 'react';
import About from '../components/about';
import Layout from '../components/layout';
import Seo from '../components/seo';

export default function AboutUs() {
  return (
    <Layout>
      <Seo
        title="About Us"
        description="Over 50 years experience in conducting appraisals, performing live and online auctions and facilitating the re-homing of your precious belongings."
      />
      <div className="py-1 lg:py-5" />
      <About />
    </Layout>
  );
}
