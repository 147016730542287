import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function About() {
  return (
    <div className="bg-white pt-16 lg:pt-10 lg:pb-24">
      <div className="bg-brand-red-800 pb-16  lg:relative lg:z-10 lg:pb-0">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8">
          <div className="relative lg:-my-8">
            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden"
            />
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:h-full lg:p-0">
              <div className="aspect-w-10 aspect-h-6 sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none overflow-hidden rounded-xl  lg:h-full">
                <StaticImage
                  src="../images/mark-cosens.jpg"
                  className="rounded-xl object-cover lg:h-full lg:w-full"
                  quality={95}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Cosens Auctions"
                />
              </div>
            </div>
          </div>
          <div className="mt-12 text-gray-100 lg:col-span-2 lg:m-0 lg:pl-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
              <blockquote>
                <div>
                  <p className="pb-6 text-lg">
                    Established in 1969, you can trust Cosens Auctions and
                    Appraisal‘s with your family‘s legacy without the hassle of
                    giving up your free time, or the family drama.
                  </p>
                  <p className="text-lg leading-7">
                    We are a small boutique, full service auction house
                    specializing in complete estates, working for families to
                    solve a myriad of problems when clearing out a family home.
                    With more than 50 years in the business, we take special
                    care and are very detail oriented while we process every
                    aspect of the home.
                    <div className="grid max-w-md grid-cols-2 py-5">
                      <li>Real estate</li>
                      <li>Collectibles</li>
                      <li>Furniture</li>
                      <li>Bric-a-Brac</li>
                      <li>Vehicles</li>
                    </div>
                  </p>
                  <p className="text-lg leading-7">We sell it all!</p>
                </div>
                <footer className="mt-6">
                  <p className="text-xl font-medium text-gray-100">
                    Mark E. Cosens
                  </p>
                  <p className="text-base font-medium text-gray-100">
                    Cosens Auctions & Appraisals
                  </p>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
